import React from 'react';
import Layout from '../components/Layout';
import RechtsgebietCard from "../components/RechtsgebietCard";
import {Container, Grid, makeStyles} from "@material-ui/core";
import {graphql, useStaticQuery} from "gatsby"


const useStyles = makeStyles(theme => ({
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
}));

export default function App() {

    const classes = useStyles();

    const data = useStaticQuery(graphql`
    {
      allCategories:allContentfulKategorie(sort: {fields: weight, order: ASC}) {
        categories:nodes {
          cardImage {
            fluid(maxWidth: 384, maxHeight: 216, resizingBehavior: FILL, quality: 95) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          slug
          title
          summary {
            summary
          }
        }
      }
    heroImage:file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "lobby-1.jpg"}) {
      relativePath
      publicURL
      img:childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
  `);

    const categoryTiles = data.allCategories.categories.map((category, i) => {
        return (
            <RechtsgebietCard key={i} rechtsgebiet={category}/>
        )
    });

    return (
        <Layout heroHeadline1="Herzlich Willkommen bei DREYER & PFEIFFER Rechtsanwälte"
                heroHeadline2="Wir sind Spezialisten für Baurecht, Verwaltungsrecht und Verkehrsrecht"
                heroImage={data.heroImage.img.fluid}
        >
            <Container className={classes.cardGrid}>
                <Grid container spacing={4}>
                    {categoryTiles}
                </Grid>
            </Container>
        </Layout>
    );
}
